<template>
  <vuestic-widget :loading="loading">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Calls</span>
        <div>
          <button
            class="btn btn-primary btn-sm mr-2"
            @click="exportXlsx()"
            :disabled="isLoadingExport"
          >
            <atom-spinner v-if="isLoadingExport" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
            <span v-else>Export <i class="fa fa-download"></i></span>
          </button>
          <button
            class="btn btn-primary btn-sm mr-2"
            @click="onRefresh()"
            :disabled="loading"
          >
            <span>Refresh <i class="fa fa-refresh"></i></span>
          </button>
        </div>
      </div>
    </template>
    <div class="pb-2 mb-3">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
            <div class="">
              <date-range-picker
                ref="picker"
                :opens="filter.date.opens"
                :locale-data="{ firstDay: 1, format: 'MMMM D, YYYY' }"
                :single-date-picker="filter.date.singleDatePicker"
                :time-picker="filter.date.timePicker"
                :time-picker24-hour="filter.date.timePicker24Hour"
                :show-week-numbers="filter.date.showWeekNumbers"
                :show-dropdowns="filter.date.showDropdowns"
                :auto-apply="filter.date.autoApply"
                :always-show-calendars="filter.date.alwaysShowCalendars"
                :linked-calendars="filter.date.linkedCalendars"
                :ranges="filter.date.ranges"
                v-model="filter.date.dateRange"
                @update="handleQueryChange"
              >
              </date-range-picker>
            </div>
            <div class="d-flex align-items-center justify-content-end flex-row mr-2">
              <search-input
                name="searchInput"
                v-model="searchFilter"
                @input="onChangeFilter"
              />
            </div>
            <div class="">
              <button
                class="btn btn-sm btn-primary pull-right"
                @click="onResetFilter()"
              >
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <datatable v-bind="tableConfig" class="le-datatable"/>
    <vuestic-modal
      :isOpen="isOpenModalTranscription"
      @ok="isOpenModalTranscription = false"
      @cancel="isOpenModalTranscription = false"
      :cancelShown="false"
      okText="Ok"
    >
      <span slot="title">Transcription</span>
      <div v-if="selectedRecord" class="transcription-wrapper">
        <p>{{  selectedRecord.transcription.message }}</p>
      </div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import axios from 'axios'
  import components from "../../common/tables/comps";
  import SearchInput from '../../common/SearchInput'
  import Moment from 'moment'
  import { extendMoment } from 'moment-range'

  const moment = extendMoment(Moment)

  export default {
    components: {
      SearchInput,
      DateRangePicker
    },

    data() {
      return {
        loading: false,
        tableConfig: {
          supportBackup: false,
          supportNested: false,
          HeaderSettings: false,
          tblClass: "table-bordered",
          tblStyle: "color: #666",
          pageSizeOptions: [10, 25, 50, 100],
          columns: (() => {
            const cols = [
              { title: "ID", field: "id", label: "Call Log ID", sortable: true, visible: false },
              { title: "Status", sortable: true, field: "status_data", tdStyle: {fontStyle: "normal"}, tdClass: "center", tdComp: "TdCallStatus" },
              { title: "Install", sortable: true, field: "website", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedValue" },
              { title: "Website", sortable: true, field: "client_referer_url", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedValue" },
              { title: "Department", sortable: true, field: "department.name", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedValue" },
              { title: "Time", sortable: true, field: "created_at", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdNestedDateValue" },
              { title: "Phone", sortable: true, field: "phone", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left", tdComp: "TdPhone" },
              { title: "Name", sortable: false, field: "contact_name", tdStyle: {fontStyle: "normal"}, tdClass: "left", thClass: "left" },
              { title: "Mobile", field: "mobile", sortable: false, visible: true, tdClass: "center", tdComp: "TdNestedYesNoValue" },
              { title: "Agent", field: "agent.name", visible: true, tdClass: "left small", thClass: "left", tdComp: "TdNestedValue" },
              { title: "Recording / Duration", sortable: false, visible: true, tdStyle: {height: "66px"}, tdClass: "center", tdComp: "Recording" },
            ];
            return cols;
          })(),
          data: [],
          total: 0,
          // selection: [],  //if this is present the row selector shows up..
          summary: {},
          query: {},
          // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
          xprops: {
            eventbus: new Vue()
          }
        },
        filter: {
          date : {
            opens: 'right',
            minDate: null,
            maxDate: null,
            singleDatePicker: false,
            timePicker: false,
            timePicker24Hour: false,
            showWeekNumbers: false,
            showDropdowns: false,
            autoApply: false,
            dateRange: {
              startDate: moment().subtract(30, 'days'),
              endDate: moment(),
            },
            linkedCalendars: true,
            ranges: {
              'Today': [moment(), moment()],
              'Last 7 Days': [moment().subtract(7, 'days'), moment()],
              'Last 30 Days': [moment().subtract(30, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            alwaysShowCalendars: false,
          }
        },
        searchFilter: null,
        isLoadingExport: false,
        isOpenModalTranscription: false,
        selectedRecord: null,
      }
    },
    mounted()  {
      this.tableConfig.xprops.eventbus.$on('onShowTranscription', (row) => {
        this.isOpenModalTranscription = true
        this.selectedRecord = row
      })
    },
    watch: {
      "tableConfig.query": {
        handler() {
          this.handleQueryChange();
        },
        deep: true
      },
    },
    methods: {
      onRefresh() {
        this.tableConfig.query.offset = 0
        this.handleQueryChange()
      },

      onChangeFilter() {
        this.handleQueryChange()
      },

      onResetFilter() {
        this.searchFilter = null;
        this.handleQueryChange()
      },

      exportXlsx () {
        this.isLoadingExport = true

        let queries = {
          ...this.tableConfig.query,
          search: this.searchFilter,
          started_at: this.filter.date.dateRange.startDate ? moment(this.filter.date.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : null,
          ended_at: this.filter.date.dateRange.endDate ? moment(this.filter.date.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : null,
        }

        axios.get(`/v1/calls/export`, { params: queries, responseType: 'blob' })
          .then((res) => {
            this.isLoadingExport = false
              const blob = new Blob([res.data], { type: 'application/xlsx' })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = 'calls.xlsx'
              link.click()
              URL.revokeObjectURL(link.href)
          })
          .catch(() => {
            this.isLoadingExport = false
          })
      },

      handleQueryChange() {
        this.loading = true;

        let queries = {
          ...this.tableConfig.query,
          search: this.searchFilter,
          started_at: this.filter.date.dateRange.startDate ? moment(this.filter.date.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : null,
          ended_at: this.filter.date.dateRange.endDate ? moment(this.filter.date.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : null,
        }

        axios.get(`/v1/calls`, { params: queries })
          .then((res) => {
            this.tableConfig.data = res.data.rows;
            this.tableConfig.total = res.data.total;
            this.loading = false;
          }).catch((e) => {
            this.loading = false;
        });
      }
    }
  };
</script>

<style lang="scss">
.vue-daterange-picker {
  display: block !important;

  //make range picker behave as adequate input
  .reportrange-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.45rem 0.75rem;
    border: .0625rem solid #e7e7e7;
    height: inherit;
  }

  .daterangepicker {
    transform: scale(1);
    opacity: 1;

    //Move ranges list to the right
    .ranges {
      order: 10;
    }
  }
}
.transcription-wrapper {
  max-height: 60vh;
  overflow-y: auto;
  padding: 10px 0;
}
</style>
