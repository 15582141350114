<template>
    <call-log-table/>
</template>
<script>
  import CallLogTable from './CallLogTable'

  export default {
    name: "Calls",
    components: {
      CallLogTable,
    },

    created() {
      if (this.$store.getters["auth/isBusinessManager"]) {
        if (this.$store.getters["auth/widgetTypeTextOnly"]) {
          this.$router.push({ name: "dashboard" });
        }
      } else if (this.user.role == "BusinessUser") {
        if (!this.user.agent_type)
        {
          this.$router.push({ name: "business.setting" });
        } else {
          if (
            this.user.agent_type == "smsAgent" ||
            this.$store.getters["auth/widgetTypeTextOnly"]
          ) {
            this.$router.push({ name: "messaging" });
          }
          if (this.user.user_type == "4" && !this.$store.getters["auth/customPageType"].includes('calls_page')) {
            this.$router.push({ name: "business.setting" });
          }
        }
      }
    },

    computed: {
      user() {
        return this.$store.state.auth.user;
      },
    },
  }
</script>
<style lang="scss" scoped>
  .leblu2 {
    color: $rb-blue !important;
  }
</style>
